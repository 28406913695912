@import "../../mixins";

.table-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__description {
    padding-top: rem(20);

    @include mediaBigDesktop {
      padding-top: big(20);
    }

    @include mediaTablet {
      padding-top: rem(15);
    }

    @include mediaMobile {
      padding-top: rem(10);
    }
  }

  & table {
    width: 100%;

    overflow: auto;

    border-collapse: collapse;
  }

  & thead {
    width: 100%;

    background-color: var(--bg-dark);
  }

  & th {
    padding: rem(13) rem(25);

    color: var(--text-light-primary);
    font: var(--font-caption-S);
    font-weight: 500;
    text-align: start;
    text-transform: uppercase;
    letter-spacing: rem(1.6);

    @include mediaBigDesktop {
      padding: big(13) big(25);

      letter-spacing: big(1.6);
    }

    @include mediaLaptop {
      padding: rem(11) rem(20) rem(12);

      letter-spacing: rem(1.4);
    }

    @include mediaTablet {
      padding: rem(11) rem(15);

      letter-spacing: rem(1.2);
    }

    @include mediaMobile {
      padding: rem(11) rem(12);
    }
  }

  & tbody {
    width: 100%;
  }

  & tr {
    width: 100%;

    &:nth-child(2n+2) {
      background-color: var(--bg-light);
    }

    &:last-child {
      & td {
        border-bottom: solid 1px var(--stroke-dark-secondary);

        @include mediaBigDesktop {
          border-bottom-width: big(1);
        }
      }
    }
  }

  & td {
    padding: rem(18) rem(25);

    color: var(--text-dark-primary);
    font: var(--font-body-S);
    text-align: start;
    vertical-align: text-top;

    @include mediaBigDesktop {
      padding: big(18) big(25);
    }

    @include mediaLaptop {
      padding: rem(15) rem(20);
    }

    @include mediaTablet {
      padding: rem(12) rem(15);
    }

    @include mediaMobile {
      padding: rem(10) rem(12);
    }

    &:first-child {
      padding-left: rem(24);

      border-left: solid 1px var(--stroke-dark-secondary);

      @include mediaBigDesktop {
        padding-left: big(24);

        border-left-width: big(1);
      }

      @include mediaLaptop {
        padding-left: rem(19);
      }

      @include mediaTablet {
        padding-left: rem(14);
      }

      @include mediaMobile {
        padding-left: rem(11);
      }
    }

    &:last-child {
      padding-right: rem(24);

      border-right: solid 1px var(--stroke-dark-secondary);

      @include mediaBigDesktop {
        padding-right: big(24);

        border-right-width: big(1);
      }

      @include mediaLaptop {
        padding-right: rem(19);
      }

      @include mediaTablet {
        padding-right: rem(14);
      }

      @include mediaMobile {
        padding-right: rem(11);
      }
      
    }
  }
}
